import { db, storage } from "../firebase"
import { setDoc, getDocs, updateDoc, getDoc, doc, collection, query, orderBy, limit, where, } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid'
import { getDownloadURL, uploadBytes, ref } from "firebase/storage"
// search only version
import algoliasearch from 'algoliasearch/lite'

const client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_SEARCH_ONLY_API_KEY);
const index = client.initIndex(process.env.VUE_APP_ALGOLIA_INDEX)

export async function uploadImage(image) {
    /** Receives an image and uploads it to the cloud
     * 
     */
    let imageUrl = 'images/' + uuidv4()
    let imageRef = ref(storage, imageUrl);
    await uploadBytes(imageRef, image)
    let downloadUrl = await getDownloadURL(imageRef)
    return downloadUrl
}

export async function getPublishedDocument(documentId) {
    /** Receives a documentId and returns the published version
     * for it
     * 
     */
    const docSnap = await getDoc(doc(db, "documents", documentId))
    let publishedDoc = docSnap.data()
    return publishedDoc['version']
}

export async function searchPublishedDocuments(searchString, userId) {
    /** Receives a search string and returns a list of publish documents
     * related with that
     * 
     */
     client.clearCache().then(() => {
      });
    let searchResults = await index.search(searchString)
    const publishedResults = [];
    for (let searchResult of searchResults.hits)
        if (searchResult.published_by_user_id) publishedResults.push(searchResult)
        else if (searchResult.original_author == userId) publishedResults.push(searchResult)
    return publishedResults
}

export async function searchAllDocuments(searchString) {
    /** Receives a search string and returns a list of publish documents
     * related with that
     * 
     */
    console.log(searchString)
    // let publishedDocuments = []
    let searchResults = await index.search(searchString)
    // console.log("search results: ", searchResults)
    // const querySnapshot = await getDocs(collection(db, "documents"));
    // querySnapshot.forEach((doc) => {
    //     publishedDocuments.push(doc.data().version)
    // });
    // console.log('all versions: ', publishedDocuments)
    // return publishedDocuments
    return searchResults.hits

}

export async function getDocumentHistory(documentId) {
    /** Get the history of edits for that published document
     * 
     */
    let q = query(collection(db, "versions"), where("document_id", "==", documentId))
    let versionIterator = await getDocs(q)
    //  let versionIterator = documentsIterator.collection('versions').list_documents()
    let all_versions = []
    versionIterator.forEach((doc) => {
        all_versions.push(doc.data())
    })
    return all_versions
}

export async function getDocumentVersion(versionId) {
    /** Get the current edited document related with that document id
     * 
     */
    let resp = await getDoc(doc(db, "versions", versionId))
    return resp.data()
}


export async function getMostRecentVersion(documentId) {
    /** Create a new version for the document 
     * of documentId using content
     * 
     */
    let q = query(collection(db, "versions"), where("document_id", "==", documentId), orderBy("created_at_s", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
    let latestDoc = null
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        latestDoc = doc.data()
    });
    return latestDoc
}

export async function createDocumentVersion(documentId, title, body, userId) {
    /** Create a new version for the document 
     * of documentId using content
     * 
     */
    let mostRecentVersion = await getMostRecentVersion(documentId)
    let updated_by_user_ids = mostRecentVersion != null ? new Set(mostRecentVersion.contributed_by_user_ids) : new Set([])
    updated_by_user_ids.add(userId)
    let newVersionId = uuidv4()
    let newDoc = {
        "document_id": documentId,
        "version_id": newVersionId,
        "title": title,
        "body": body,
        "created_at_s": new Date().getTime(),
        "created_by_user_id": userId,
        // all users that contributed to that document
        "contributed_by_user_ids": Array.from(updated_by_user_ids)
    }
    await setDoc(doc(db, "versions", newVersionId), newDoc)
    return newDoc
}

export async function createNewDocument(title, body, userId) {
    /** Publishes a new version
     */
    let documentId = uuidv4()
    let newDoc = {
        "document_id": documentId,
        "published_by_user_id": "",
        "created_at_s": new Date().getTime(),
        "published_at_s": "",
        "original_author": userId,
    }
    await setDoc(doc(db, "documents", documentId), newDoc)
    let version = await createDocumentVersion(documentId, title, body, userId)
    await updateDoc(doc(db, "documents", documentId), { "version": version })
    return newDoc
}

export async function publishDocumentVersion(documentId, version, userId) {
    /** Publishes a new version
     */
    let newDoc = {
        "published_by_user_id": userId,
        "version_id": version['version_id'],
        "version": version,
        "published_at_s": new Date().getTime()
    }
    await updateDoc(doc(db, "documents", documentId), newDoc)
    return newDoc
}

export async function getDocumentInfo(document_id) {
    /** Gets Document related to a version
     */
    let documentInfo = await getDoc(doc(db, "documents", document_id))
    return documentInfo.data()
}

export async function correctContributeDB(documentId) {
    //get all versio
    let versions = await getDocumentHistory(documentId);
    let mostRecentVersion = await getMostRecentVersion(documentId)
    let firstVersion = mostRecentVersion;
    let userSet = new Set([])
    for (let version of versions) {
        if (version.created_at_s <= mostRecentVersion.created_at_s) {
            for (let userId of version.contributed_by_user_ids)
                userSet.add(userId)
        }
        if (version.created_at_s < firstVersion.created_at_s) firstVersion = version
    }
    await updateDoc(doc(db, "documents", documentId), { "original_author": firstVersion.created_by_user_id })
    console.log("função original_author correction: ", firstVersion.document_id, firstVersion.created_at_s, firstVersion.created_by_user_id)
}