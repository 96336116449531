<template>
  <div>
    <v-tour
      name="edit-news"
      :steps="editNews"
      :options="tourOptions"
      :callbacks="tourCallbacksEdit"
    ></v-tour>
    <v-tour
      name="submit-news"
      :steps="submitEdition"
      :options="tourOptions"
      :callbacks="tourCallbacksSubmit"
    ></v-tour>
    <v-toolbar>
      <v-container>
        <v-row align="center">
          <v-btn
            icon
            :loading="loadClose"
            :enabled="loadClose"
            @click="closeEditing"
            v-if="editing"
            class="ml-n4 mr-1 ms-md-0"
          >
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
          <v-col cols="auto" class="ml-n4 ml-md-0">
            <router-link to="/">
              <v-img
                :src="
                  $vuetify.breakpoint.xs
                    ? require('../assets/Red_Arrow_Icon.png')
                    : require('../assets/Topbar_Logo.png')
                "
                contain
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" loading> </v-skeleton-loader>
                </template>
              </v-img>
            </router-link>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              icon
              @click="submitText"
              v-if="editing"
              :loading="loadSubmit"
              :disabled="loadSubmit || !documentChanged"
              id="submit-tour"
            >
              <v-icon color="primary">mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
              v-if="editing"
              icon
              @click.stop="historyDrawer = !historyDrawer"
            >
              <v-icon color="secondary">mdi-history</v-icon>
            </v-btn>
            <v-btn
              icon
              :disabled="locked || loadEdit"
              @click="editText"
              :loading="loadEdit"
              v-if="!editing"
              id="edit-tour"
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="accountDrawer = !accountDrawer"
              v-if="$store.state.user"
              class="mr-n4 mr-md-0"
            >
              <v-avatar v-if="avatarUrl">
                <v-img :src="avatarUrl" v-if="avatarUrl">
                  <template v-slot:placeholder>
                    <v-skeleton-loader type="avatar" loading>
                    </v-skeleton-loader>
                  </template>
                </v-img>
              </v-avatar>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
            <v-btn
              @click="popLogInDialog"
              outlined
              v-else
              class="mr-n4 mr-md-0"
            >
              {{ $t("action.signIn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container>
      <!-- <br />
      <br /> -->
      <v-row align="center">
        <v-col>
          <v-skeleton-loader :loading="loadEditors" type="heading">
            <editor-content :editor="titleEditor" />
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <v-skeleton-loader
        :loading="displayedInfoReady"
        type="card-heading"
        transition="fab-transition"
      >
        <v-row align="center" no-gutters>
          <v-card
            v-for="(infoCard, i) in infoCards"
            :key="'A' + i"
            flat
            class="d-none d-sm-flex mt-n1 mr-n5"
          >
            <v-list-item two-line class="pa-0" dense>
              <v-list-item-avatar class="ma-0">
                <v-icon v-text="infoCard.icon"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="d-inline-flex"
                  v-text="infoCard.title"
                >
                </v-list-item-title>
                <v-list-item-subtitle
                  class="d-inline-flex"
                  v-text="infoCard.info"
                >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card
            v-for="(infoCard, j) in infoCards"
            :key="'B' + j"
            flat
            class="d-flex d-sm-none mx-2"
          >
            <v-card-text class="pa-0">
              <nobr v-text="infoCard.title + ' '"></nobr>
              <nobr v-text="infoCard.info" class="text--primary"></nobr>
            </v-card-text>
          </v-card>
        </v-row>
      </v-skeleton-loader>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-skeleton-loader :loading="loadEditors" type="list-item-three-line">
            <editor-content :editor="bodyEditor" />
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100, arrow: true }"
        :editor="bodyEditor"
        v-if="editing"
      >
        <v-btn
          @click="bodyEditor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': bodyEditor.isActive('bold') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': bodyEditor.isActive('italic') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': bodyEditor.isActive('strike') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-strikethrough</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': bodyEditor.isActive('strike') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-title</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': bodyEditor.isActive('strike') }"
          icon
          plain
          dark
        >
          <v-icon size="1.3em">mdi-format-title</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': bodyEditor.isActive('strike') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn
          @click="bodyEditor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': bodyEditor.isActive('strike') }"
          icon
          plain
          dark
        >
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>
      </bubble-menu>
      <v-navigation-drawer
        v-model="accountDrawer"
        right
        app
        bottom
        temporary
        v-if="$store.state.user"
      >
        <v-list dense>
          <v-list-item to="/profile">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-edit</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("profile.update") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="signOutBtnClick" color="secondary">
            <v-list-item-icon>
              <v-icon color="secondary">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("profile.signOut") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer v-model="historyDrawer" right app bottom temporary>
        <v-list dense>
          <v-list-group
            v-for="(item, k) in items"
            v-model="item.active"
            @click="displayVersion(item.version)"
            :key="'C' + k"
            prepend-icon
            value
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-avatar v-if="item.userPictureUrl">
                  <!-- <v-avatar v-if="true"> -->
                  <!-- <v-img :src="require('../assets/' + item.avatar)"></v-img> -->
                  <v-img
                    :src="item.userPictureUrl"
                    v-on:error="onImgError(item)"
                  >
                    <template v-slot:placeholder>
                      <v-skeleton-loader type="avatar" loading>
                      </v-skeleton-loader>
                    </template>
                  </v-img>
                </v-avatar>
                <v-icon v-else>mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>

            <v-list-item v-if="$store.state.isAdmin">
              <v-list-item-content>
                <!-- TODO: Only show approval chip when logged in an approval role. -->
                <v-list-item-action>
                  <v-btn
                    :disabled="
                      item.version.version_id == publishedVersion.version_id
                    "
                    @click="approveDocVersion(item.version)"
                    x-small
                    dense
                    >Approve
                  </v-btn>
                </v-list-item-action>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import FontFamily from "@tiptap/extension-font-family";
import TextStyle from "@tiptap/extension-text-style";
import History from "@tiptap/extension-history";
import Image from "@tiptap/extension-image";
import * as firebaseui from "firebaseui";
import mixpanel from "mixpanel-browser";
import {
  getPublishedDocument,
  createDocumentVersion,
  getDocumentHistory,
  publishDocumentVersion,
  uploadImage,
  getDocumentInfo,
} from "../api/documents";
import { getUserImageUrl, getUserInfo } from "../api/users";

export default {
  props: {
    locked: {
      type: Boolean,
      default: false,
      required: false,
    },
    initialHtml: {
      type: String,
      required: false,
    },
  },
  components: {
    EditorContent,
    BubbleMenu,
  },

  data() {
    return {
      publishedVersion: {},
      displayedApprover: "",
      bodyEditor: null,
      titleEditor: null,
      editing: false,
      htmlText: "",
      documentId: "",
      items: [],
      historyDrawer: false,
      accountDrawer: false,
      displayedDate: "",
      displayedAuthor: "",
      loadEdit: false,
      loadSubmit: false,
      loadClose: false,
      loadEditors: true,
      documentChanged: false,
      displayedVersion: null,
      displayedHistory: false,
      infoCards: [],
      displayedInfoReady: true,
      editNews: [
        {
          target: "#edit-tour", // We're using document.querySelector() under the hood
          content: this.$t("tour.editNews"),
          params: {
            enableScrolling: false,
          },
        },
      ],
      submitEdition: [
        {
          target: "#submit-tour", // We're using document.querySelector() under the hood
          content: this.$t("tour.saveEdit"),
          params: {
            enableScrolling: false,
          },
        },
      ],
      tourOptions: {
        startTimeout: 1000,
        labels: {
          buttonStop: "Ok",
        },
      },
      tourCallbacksEdit: {
        onStart:  () => {
          mixpanel.track("Tour event", {
            event_type: "tour start",
            event_name: "Edit news"
          });
          console.log("callback tour start ",)
        },
        onStop: () => {
          mixpanel.track("Click - Tour event - close", {
            event_type: "tour ended (stopped)",
            event_name: "Edit news"
          });
        },
      },
      tourCallbacksSubmit: {
        onStart:  () => {
          mixpanel.track("Tour event", {
            event_type: "tour start",
            event_name: "Save news"
          });
        },
        onStop: () => {
          mixpanel.track("Click - Tour event - close", {
            event_type: "tour ended (stopped)",
            event_name: "Save news"
          });
        },
      },
    };
  },
  methods: {
    onImgError: function (item) {
      console.log("setting ", item.userPictureUrl, " to null");
      item.userPictureUrl = null;
    },
    dataURLtoFile: function (imageType, dataUrl) {
      // var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      let bstr = atob(dataUrl);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "image", { type: "image/" + imageType });
    },
    replaceBase64: async function () {
      let parseGroups = [];
      let imageFile = null;
      let imageURL = "";
      const regEx =
        /<img[^>]*?src=['"](data:image\/([a-zA-Z]*);base64,([^'|")]*))['"].*?>/g;
      let htmlString = this.bodyEditor.getHTML();
      parseGroups = htmlString.matchAll(regEx);
      if (htmlString.match(/;base64,/))
        this.alert = {
          control: true,
          message: this.$t("action.imageUploading"),
        };
      for (const parseGroup of parseGroups) {
        console.log("group 1st content: ", parseGroup[1]);
        if (parseGroup[3]) {
          imageFile = this.dataURLtoFile(parseGroup[2], parseGroup[3]);
          imageURL = await uploadImage(imageFile);
          htmlString = htmlString.replace(parseGroup[1], imageURL);
        }
      }
      this.bodyEditor.commands.setContent(htmlString);
      if (imageURL)
        this.alert = {
          control: true,
          message: this.$t("message.imagesReady"),
        };
    },
    submitText: async function () {
      this.loadSubmit = true;
      await this.replaceBase64();
      this.saveNews();
      mixpanel.track("Document Submitted", { document_id: this.documentId });
    },
    editText: function () {
      this.loadEdit = true;
      mixpanel.track("Edit pencil click")
      this.enableEdit();
    },
    disableEdit: async function () {
      this.documentChanged = false;
      this.bodyEditor.setEditable(false);
      this.titleEditor.setOptions({ editable: false });
      this.editing = false;
      this.historyDrawer = false;
      if (this.items.length > 0)
        this.displayVersion(await getPublishedDocument(this.documentId));
      this.loadClose = false;
    },
    checkPermission: function () {
      if (this.$store.state.user) return true;
      else {
        this.$store.commit("setLoginDialog", true);
        this.loadEdit = false;
      }
    },
    enableEdit: async function () {
      if (this.checkPermission()) {
        await this.loadDocumentHistory();
        this.bodyEditor.setOptions({ editable: true });
        this.titleEditor.setOptions({ editable: true });
        this.editing = true;
        this.loadEdit = false;
        this.$tours["submit-news"].start();
        mixpanel.track("Started editing")
      }
    },
    closeEditing: function () {
      this.loadClose = true;
      this.disableEdit();
    },

    displayVersion: async function (version) {
      this.loadEditors = true;
      this.displayedInfoReady = true;
      // sets the initial content of the body if exists
      if (version.body) {
        console.log("version body: ", version.body);
        this.bodyEditor.chain().setContent(version.body).run();
      }
      // sets the initial content of the title if exists
      if (version.title) {
        this.titleEditor.chain().setContent(version.title).run();
      }
      this.displayedVersion = version;
      this.displayedDate = version.created_at_s;
      this.displayedDate = new Intl.DateTimeFormat(this.$i18n.locale, {
        dateStyle: "short",
        timeStyle: "short",
      }).format(new Date(this.displayedDate));
      this.loadEditors = false;
      let documentInfo = await getDocumentInfo(version.document_id);
      let authorInfo = {};
      if (documentInfo.original_author)
        authorInfo = await getUserInfo(documentInfo.original_author);
      this.displayedAuthor =
        authorInfo?.user_name || this.$t("message.anonymous");
      let approverInfo = {};
      if (documentInfo.published_by_user_id)
        approverInfo = await getUserInfo(documentInfo.published_by_user_id);
      this.displayedApprover =
        approverInfo?.user_name || this.$t("message.anonymous");
      this.loadEditors = false;
      let lastVersionTitle = this.$t("message.lastApprovedVersion"),
        originalAuthorTitle = this.$t("message.originalAuthor"),
        historyTitle = this.$t("message.history"),
        approvedByTitle = this.$t("message.approver"),
        displayedDate = this.displayedDate,
        displayedAuthor = this.displayedAuthor,
        numberOfEditors = null,
        displayedApprover = this.displayedApprover;
      if (documentInfo?.version)
        if (documentInfo.version?.contributed_by_user_ids)
          numberOfEditors =
            documentInfo.version.contributed_by_user_ids.length +
            " " +
            this.$t("message.independentEditors");
        else numberOfEditors = "na";
      else numberOfEditors = "na";
      if (documentInfo?.version_id == version.version_id) {
        this.infoCards = [
          {
            title: lastVersionTitle,
            info: displayedDate,
            icon: "mdi-clock-outline",
          },
          {
            title: originalAuthorTitle,
            info: displayedAuthor,
            icon: "mdi-medal-outline",
          },
          { title: historyTitle, info: numberOfEditors, icon: "mdi-history" },
          {
            title: approvedByTitle,
            info: displayedApprover,
            icon: "mdi-check-circle-outline",
          },
        ];
      } else {
        this.infoCards = [
          {
            title: lastVersionTitle,
            info: displayedDate,
            icon: "mdi-clock-outline",
          },
        ];
      }
      this.displayedInfoReady = false;
    },
    popLogInDialog: function () {
      this.$store.commit('setLoginDialog', true)
      mixpanel.track('Click - Login - Doc Toolbar')
    },
    saveNews: async function () {
      console.log("Document update author: ", this.$store.state.user);
      // save new version
      let version = await createDocumentVersion(
        this.documentId,
        this.titleEditor.getHTML(),
        this.bodyEditor.getHTML(),
        this.$store.state.user.uid
      );
      console.log("new version : ", version);
      this.alert = { control: true, message: "action.submitted" };
      this.loadSubmit = false;
      this.loadDocumentHistory();
      this.displayedVersion = version;
      this.documentChanged = false;
    },
    signOutBtnClick: function () {
      this.closeEditing();
      this.$store.dispatch("signOut");
    },
    getPictureUrl: async function (userId) {
      console.log("getting user image: ", userId);
      try {
        return await getUserImageUrl(userId);
      } catch (error) {
        console.log("cannot load user image for user with id: ", userId);
        console.log(error);
        return null;
      }
    },
    getUserInfo: async function (userId) {
      console.log("getting user image: ", userId);
      try {
        return await getUserInfo(userId);
      } catch (error) {
        console.log("cannot load user image for user with id: ", userId);
        console.log(error);
        return null;
      }
    },
    loadDocumentHistory: async function () {
      let allVersions = await getDocumentHistory(this.documentId);
      console.log("all document versions: ", allVersions);
      this.items = [];
      for (let i = 0; i < allVersions.length; i++) {
        let version = allVersions[i];
        this.items.push({
          title: new Intl.DateTimeFormat(this.$i18n.locale, {
            dateStyle: "short",
            timeStyle: "short",
          }).format(new Date(version.created_at_s)),
          version: version,
          active: false,
        });
      }
      // sorts descending
      this.items.sort((a, b) => {
        if (a.version.created_at_s > b.version.created_at_s) return -1;
        else return 1;
      });
      // display the most recently edited item
      if (this.items.length > 0) this.displayVersion(this.items[0].version);
      // console.log("itms: ", this.items);
      this.items[0].active = true;
      this.loadImageUrls(this.items);
      this.loadUserNames(this.items);
    },
    loadImageUrls: async function (items) {
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let userPictureUrl = await this.getPictureUrl(
          item.version.created_by_user_id
        );
        item.userPictureUrl = userPictureUrl;
      }
      this.items.splice(items.length);
    },
    loadUserNames: async function (items) {
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let userInfo = await this.getUserInfo(item.version.created_by_user_id);
        item.subtitle = userInfo?.user_name || this.$t("message.anonymous");
      }
      this.items.splice(items.length);
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          mixpanel.track("Scrolled to the bottom", {
            document_id: this.documentId,
          });
        }
      };
    },
    checkChanges: function () {
      this.loadSubmit = true;
      let changesCheck = {
        changedTitle: this.titleEditor.getHTML() != this.displayedVersion.title,
        changedBody: this.bodyEditor.getHTML() != this.displayedVersion.body,
      };
      this.documentChanged =
        changesCheck.changedTitle || changesCheck.changedBody;
      this.loadSubmit = false;
    },
    async approveDocVersion(version) {
      console.log("Document approved is: ", version);
      console.log("approval granted by: ", this.$store.state.user.uid);
      await publishDocumentVersion(
        version["document_id"],
        version,
        this.$store.state.user.uid
      );
      this.publishedVersion = version;
      //TODO: add translation of this alert
      this.alert = { control: true, message: "action.approved" };
    },
  },

  async mounted() {
    this.documentId = this.$route.params.doc_id;
    // gets the document with that id
    let version = await getPublishedDocument(this.documentId);
    this.publishedVersion = version;
    // if(this.initialHtml) this.bodyContent = require("../assets/"+this.initialHtml) ["html"]
    this.bodyEditor = new Editor({
      extensions: [
        StarterKit,
        TextStyle,
        FontFamily,
        Image,
        Placeholder.configure({
          placeholder: this.$t("editor.content"),
        }),
      ],
      onUpdate: () => {
        this.checkChanges();
      },
    });
    this.titleEditor = new Editor({
      extensions: [
        Document,
        TextStyle,
        FontFamily,
        Text,
        History,
        Heading.configure({
          levels: [1],
        }),
        Placeholder.configure({
          placeholder: this.$t("editor.title"),
        }),
      ],
      onUpdate: () => {
        this.checkChanges();
      },
    });
    this.disableEdit();
    this.bodyEditor.chain().setFontFamily("Georgia").run();
    this.titleEditor.chain().setFontFamily("Georgia").run();

    this.displayVersion(version);

    mixpanel.track("Document visit", { document_id: this.documentId });
    mixpanel.people.increment("document_views");
    this.$tours["edit-news"].start();
  },
  beforeDestroy() {
    this.bodyEditor.destroy();
  },
  computed: {
    finishedRedirect: function () {
      const ui = firebaseui.auth.AuthUI.getInstance();
      if (ui) {
        return ui.isPendingRedirect();
      } else {
        return "";
      }
    },
    alert: {
      get: function () {
        return this.$store.state.alert;
      },
      set: function (alert) {
        this.$store.commit("setAlert", alert);
      },
    },
    avatarUrl: {
      get: function () {
        if (this.$store.state.user.photoURL)
          return this.$store.state.user.photoURL;
        else return null;
      },
    },
  },
};
</script>

<style lang="scss">
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
}

.ProseMirror:focus {
  outline: none;
}

.bubble-menu {
  display: flex;
  background-color: #333333;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
  font-family: Georgia;
}
</style>