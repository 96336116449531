<template>
    <v-snackbar
      v-model="alert.control"
      :timeout="2000"
    >
      {{ $t(alert.message) }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="endOfAlert()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: "Alerts",
  methods: {
    endOfAlert: function () {
      this.alert.control = false;
    },
  },
  computed:{
    alert:{
      get: function(){
         return this.$store.state.alert;
      },
      set: function(alert){
        this.$store.commit('setAlert', alert)
      }
    }
  }
};
</script>