<template>
  <div>
    <MainToolbar />
    <br />
    <v-tour
      name="my-draft-tour"
      :steps="draftSteps"
      :options="tourOptions"
      :callbacks="tourCallbacksDraft"
    ></v-tour>
    <v-tour
      name="sababa-tour"
      :steps="sababaNewsSteps"
      :options="tourOptions"
      :callbacks="tourCallbacksCreate"
    ></v-tour>
    <v-container>
      <v-row>
        <v-col cols="auto">
          <!-- If we want to update the search @ every new input
        @change="performSearch"-->
          <v-text-field
            v-model="searchValue"
            prepend-icon="mdi-magnify"
            single-line
            @change="
              mixpanel.track('Search field event', {
                event_type: 'change',
                search_input: searchValue,
              })
            "
            @focus="
              mixpanel.track('Search field event', {
                event_type: 'focus',
                search_input: searchValue,
              })
            "
            @keydown.enter="performSearch"
            @click:prepend="performSearch"
          ></v-text-field>
          <!-- @input="performSearch" -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <v-skeleton-loader
            :loading="loadingList"
            type="divider, list-item-three-line, divider"
            width="500px"
          >
            <v-list v-on:load="console.log(carregou)">
              <v-divider />
              <template v-for="(item, index) in newsList">
                <v-list-item
                  :key="item.documentId"
                  :to="'/document/' + item.documentId"
                  data-cy="news-list"
                  class="ms-n4"
                  dense
                  @click="
                    mixpanel.track('News list event', {
                      event_type: 'click',
                      document_id: item.documentId,
                    })
                  "
                  @mouseover="
                    mixpanel.track('News list event', {
                      event_type: 'on hover',
                      document_id: item.documentId,
                    })
                  "
                >
                  <v-list-item-content>
                    <!-- <v-list-item-content  class="text-truncate no-wrap"> -->
                    <v-list-item-title class="pb-1" id="news-tour"
                      ><h2>{{ item.displayTitle }}</h2></v-list-item-title
                    >
                    <v-list-item-subtitle
                      v-if="item.draftStatus"
                      class="info--text"
                      id="draft-tour"
                      ><div>
                        {{ $t("message.yourDraft") }}
                      </div></v-list-item-subtitle
                    >
                    <v-list-item-subtitle v-else>
                      {{ item.displayMetadata }}
                    </v-list-item-subtitle>

                    <v-list-item-content class="d-inline-block text-truncate">
                      {{ item.displayBody }}
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < newsList.length - 1"
                  :key="index + 'A'"
                ></v-divider>
              </template>
            </v-list>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <Alerts />
    <Login />
  </div>
</template>

<script>
import MainToolbar from "../components/MainToolbar.vue";
import {
  searchPublishedDocuments,
  getMostRecentVersion,
  //correctContributeDB,
} from "../api/documents";
import Alerts from "../components/Alerts.vue";
import Login from "../components/Login.vue";
import mixpanel from "mixpanel-browser";

export default {
  name: "ExplorePage",
  components: {
    MainToolbar,
    Alerts,
    Login,
  },
  data: function () {
    return {
      newsList: [],
      searchValue: "",
      loadingList: false,
      mixpanel: mixpanel,
      draftSteps: [
        {
          target: "#draft-tour", // We're using document.querySelector() under the hood
          content: this.$t("tour.accessDraft"),
          params: {
            enableScrolling: false,
          },
        },
      ],
      sababaNewsSteps: [
        {
          target: "#create-news-tour", // We're using document.querySelector() under the hood
          content: this.$t("tour.createNews"),
          params: {
            enableScrolling: false,
          },
        },
      ],
      tourOptions: {
        startTimeout: 1000,
        labels: {
          buttonStop: "Ok",
        },
      },
      tourCallbacksDraft: {
        onStart: () => {
          mixpanel.track("Tour event", {
            event_type: "tour start",
            event_name: "edit draft"
          });
        },
        onStop: () => {
          mixpanel.track("Tour event", {
            event_type: "tour ended (stopped)",
            event_name: "edit draft"
          });
        }
      },
      tourCallbacksCreate: {
        onStart: () => {
          mixpanel.track("Tour event", {
            event_type: "tour start",
            event_name: "create news"
          });
        },
        onStop: () => {
          mixpanel.track("Tour event", {
            event_type: "tour ended (stopped)",
            event_name: "create news"
          });
        }
      },
    };
  },
  methods: {
    async performSearch() {
      this.loadingList = true;
      let userId = null;
      mixpanel.track("Search field event", {
        event_type: "Search trigered",
        search_input: this.searchValue,
      });
      if (this.$store.state.user) userId = this.$store.state.user.uid;
      let searchResult = await searchPublishedDocuments(
        this.searchValue,
        userId
      );
      if (this.$store.state.user)
        searchResult.push(
          await searchPublishedDocuments(this.$store.state.user.uid, userId)
        );
      this.newsList = [];
      for (let index in searchResult) {
        let doc = searchResult[index];
        if (doc.version) {
          let version = doc.version;
          let displayBody = "";
          let displayTitle = "";
          let displayMetadata = "";
          let numberOfContributors = 1;
          displayTitle = version?.title
            ? version.title.match(/<h1>(.*?)<\/h1>/)[1]
            : "";
          displayBody = version?.body
            ? version.body.replaceAll(/(<([^>]+)>)/gi, "")
            : "";
          if (version?.contributed_by_user_ids) {
            numberOfContributors = version.contributed_by_user_ids.length;
            displayMetadata =
              numberOfContributors +
              " " +
              this.$t("message.independentEditors");
          } else {
            displayMetadata = "na";
            numberOfContributors = 1;
          }
          let draftStatus = !doc.published_by_user_id || false;
          if (draftStatus) {
            let lastVersion = await getMostRecentVersion(doc.document_id);
            displayBody =
              lastVersion.body.replaceAll(/(<([^>]+)>)/gi, "") || "";
            displayTitle = lastVersion.title.match(/<h1>(.*?)<\/h1>/)[1] || "";
          }
          let listedNew = {
            //title: displayTitle,
            displayTitle: displayTitle,
            //body: version.body,
            displayBody: displayBody || "",
            documentId: version.document_id,
            displayMetadata: displayMetadata,
            draftStatus: draftStatus,
          };
          if (draftStatus) this.newsList.splice(0, 0, listedNew);
          else this.newsList.push(listedNew);
          //correctContributeDB(doc.objectID)
        }
      }
      this.loadingList = false;
      if (this.newsList[0].draftStatus) this.$tours["my-draft-tour"].start();
      else this.$tours["sababa-tour"].start();
    },
  },
  async mounted() {
    this.performSearch();
    mixpanel.track("Visited explore page");
  },
};
</script>