var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"ml-n4 ml-md-0",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":_vm.$vuetify.breakpoint.xs
                  ? require('../assets/Red_Arrow_Icon.png')
                  : require('../assets/Topbar_Logo.png'),"contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"image","loading":""}})]},proxy:true}])})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-n4 mr-md-0",attrs:{"color":"primary","loading":_vm.loadAddNews,"disabled":_vm.loadAddNews,"id":"create-news-tour"},on:{"click":_vm.submitNews,"mouseover":function($event){return _vm.mixpanel.track('Create news button event', {
                event_type: 'on hover',
              })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-square-edit-outline ")]),_vm._v(" "+_vm._s(_vm.$t("action.createNews"))+" ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$store.state.user)?_c('v-btn',{staticClass:"mr-n4 mr-md-0",attrs:{"icon":""},on:{"click":_vm.avatarClk,"mouseover":function($event){return _vm.mixpanel.track('Avatar button event', {
                event_type: 'on hover',
              })}}},[(_vm.avatarUrl)?_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.avatarUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"avatar","loading":""}})]},proxy:true}],null,false,3997584440)})],1):_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1):_c('v-btn',{staticClass:"mr-n4 mr-md-0",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.logInClick,"mouseover":function($event){return _vm.mixpanel.track('Log-in button event', {
                event_type: 'on hover',
              })}}},[_c('v-icon',[_vm._v(" mdi-account-circle-outline ")]),(!_vm.$vuetify.breakpoint.xs)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("action.signIn"))+" ")]):_vm._e()],1)],1)],1)],1)],1),(_vm.$store.state.user)?_c('v-navigation-drawer',{attrs:{"right":"","app":"","bottom":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("profile.update")))])],1)],1),_c('v-list-item',{on:{"click":_vm.signOutBtnClick}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("profile.signOut")))])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }