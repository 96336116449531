<template>
    <div>
      <Editor />
      <Alerts />
      <Login  />
    </div>
</template>

<script>
import Editor from '../components/EditorComponent.vue'
import Alerts from "../components/Alerts.vue";
import Login from "../components/Login.vue";

export default{
  name: "DocumentPage",
  components:{
    Editor,
    Alerts,
    Login
  },
  mounted(){
  }
}
</script>

<style>
/* #app {
  font-family: Georgia, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
