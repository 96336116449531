
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage"
const firebaseConfig = require('../firebaseConfig.json');
const firebaseApp = initializeApp(firebaseConfig[process.env.NODE_ENV])
const db = getFirestore();
const storage = getStorage();
// if(process.env.NODE_ENV == 'development')
// {
//     console.log('connecting to firebase emulator at ', process.env.VUE_APP_FIREBASE_EMULATOR_HOST_NAME, 8081)
//     connectFirestoreEmulator(db, process.env.VUE_APP_FIREBASE_EMULATOR_HOST_NAME, 8081)
// }

export {firebaseApp, db, storage}