import Vue from 'vue'
import Vuex from 'vuex'
import { router } from '../router.js'
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/vue";
import jwt_decode from "jwt-decode";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isAdmin: false,
    alert: {
      control: false,
      message: ""
    },
    loginDialog: false,
    auth: {},
    firebaseGUI: {},
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },
    setAlert(state, alert) {
      state.alert = alert;
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserName(state, userName){
      state.user.displayName = userName;
    },
    setUserAvatar(state, userAvatar){
      state.user.photoURL = userAvatar
    },
    setLoginDialog(state, loginDialog) {
      state.loginDialog = loginDialog;
    },
    setAuth(state) {
      state.auth = getAuth();
    },
    setFirebaseGUI(state) {
      state.firebaseGUI = firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(state.auth);
    }
  },
  actions: {
    registerLogin(context) {
      onAuthStateChanged(context.state.auth, (user) => {
        if (user) {
          context.commit('setUser', user);
          context.commit('setLoginDialog', false);
          // get the token and set if the user is an admin
          let isAdmin = jwt_decode(user.accessToken).is_admin;
          context.commit('setIsAdmin', !!isAdmin);
          mixpanel.identify(user.uid);
          mixpanel.register({ Name: user.displayName, Avatar: user.photoURL });
          mixpanel.people.set({
            $email: user.email,
            $name: user.displayName,
            $avatar: user.photoURL,
          });
          Sentry.setUser({
            email: user.email,
            username: user.name,
            id: user.uid,
          });
          mixpanel.track('Log-in successful')
        } else {
          context.commit('setUser', user)
        }
      });
    },
    signOut(context) {
      const auth = context.state.auth;
      signOut(auth).then(() => {
        const alert = {
          control: true,
          message: "profile.signedOut"
        }
        context.commit('setAlert', alert);
        console.log("Router current Route: ", router.currentRoute)
        if (router.currentRoute.path == '/profile') router.push('/')
      })
      mixpanel.track('Log-out successful')
    },
  }
})