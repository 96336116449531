import Vue from 'vue'
import { router } from './router.js'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { initializeApp } from 'firebase/app';
import i18Messages from './i18n.json';
import VueI18n from 'vue-i18n';
import { getAnalytics } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';
import store from './store'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

store.subscribe((mutation, state) => {
  console.log('VueX mutation subscribe/state ', mutation, state)
  //Note1: the firebaseGUI couldn't be called here as it results
  //in a circular object error.
  //Note2: It was chosen not to invoke alerts and dialog variables
  //here as those may be disconnected from what the user did
  //(e.g. may be a sign-out alert that haven't timed out)
  let store = {
    auth: state.auth,
    user: state.user,
    isAdmin: state.isAdmin,
  }
  localStorage.setItem('store', JSON.stringify(store))
});
store.commit('initialiseStore');

const firebaseConfig = require('../firebaseConfig.json');
const firebaseApp = initializeApp(firebaseConfig[process.env.NODE_ENV])
const mixpanelConfig = require('../mixpanelConfig.json');
const packageConfig = require('../package.json');
  


if (process.env.NODE_ENV == 'production') {
  mixpanel.init(mixpanelConfig.prod.projectToken, { debug: false });
  getAnalytics();
  Sentry.init({
    Vue,
    dsn: "https://db6e1e90219b4d7f9f4578dc08a50c0b@o1113618.ingest.sentry.io/6144278",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "sababa.news", /^\//],
      }),
    ],
    release: packageConfig.name + '@' + packageConfig.version,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // TODO: We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
else mixpanel.init(mixpanelConfig.dev.projectToken, { debug: true });


//Guard against non-logged access
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (store.state.auth.currentUser) next()
    else {
      next({ name: "ExplorePage" })
      console.log("Router says: https://youtu.be/GwmJ76VjXaE")
    }
  } else {
    next();
  }
});

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0], // set locale
  fallbacklocale: 'en',
  messages: i18Messages, // set locale messages
})

Vue.use(VueTour)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  beforeCreate() {
    this.$store.commit('setAuth');
    this.$store.dispatch('registerLogin');
    this.$store.commit('setFirebaseGUI');
  },
  vuetify,
  firebaseApp,
  render: h => h(App)
}).$mount('#app');
