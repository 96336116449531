import ExplorePage from './pages/ExplorePage.vue';
import DocumentPage from './pages/DocumentPage.vue';
import ProfilePage from './pages/ProfilePage.vue';
import PrivacyPolicy from './pages/PrivacyPolicy.vue';
import TermsOfService from './pages/TermsOfService.vue';
import VueRouter from 'vue-router';
import Vue from 'vue'

const router = new VueRouter({
    routes: [
      { path: '/profile', component: ProfilePage, name: "ProfilePage", meta: { requiresAuth: true } },
      { path: '/document/:doc_id', component: DocumentPage, name: "DocumentPage", meta: { requiresAuth: false } },
      { path: '/', component: ExplorePage, name: "ExplorePage", meta: { requiresAuth: false } },
      { path: '/privacy', component: PrivacyPolicy, name: "PrivacyPolicy", meta: { requiresAuth: false } },
      { path: '/tos', component: TermsOfService, name: "TOS", meta: { requiresAuth: false } }
    ] // short for `routes: routes`
  })

  Vue.use(VueRouter)

  export {router}