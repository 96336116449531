var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainToolbar'),_c('br'),_c('v-tour',{attrs:{"name":"my-draft-tour","steps":_vm.draftSteps,"options":_vm.tourOptions,"callbacks":_vm.tourCallbacksDraft}}),_c('v-tour',{attrs:{"name":"sababa-tour","steps":_vm.sababaNewsSteps,"options":_vm.tourOptions,"callbacks":_vm.tourCallbacksCreate}}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","single-line":""},on:{"change":function($event){return _vm.mixpanel.track('Search field event', {
              event_type: 'change',
              search_input: _vm.searchValue,
            })},"focus":function($event){return _vm.mixpanel.track('Search field event', {
              event_type: 'focus',
              search_input: _vm.searchValue,
            })},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.performSearch.apply(null, arguments)},"click:prepend":_vm.performSearch},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingList,"type":"divider, list-item-three-line, divider","width":"500px"}},[_c('v-list',{on:{"load":function($event){return _vm.console.log(_vm.carregou)}}},[_c('v-divider'),_vm._l((_vm.newsList),function(item,index){return [_c('v-list-item',{key:item.documentId,staticClass:"ms-n4",attrs:{"to":'/document/' + item.documentId,"data-cy":"news-list","dense":""},on:{"click":function($event){return _vm.mixpanel.track('News list event', {
                    event_type: 'click',
                    document_id: item.documentId,
                  })},"mouseover":function($event){return _vm.mixpanel.track('News list event', {
                    event_type: 'on hover',
                    document_id: item.documentId,
                  })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pb-1",attrs:{"id":"news-tour"}},[_c('h2',[_vm._v(_vm._s(item.displayTitle))])]),(item.draftStatus)?_c('v-list-item-subtitle',{staticClass:"info--text",attrs:{"id":"draft-tour"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("message.yourDraft"))+" ")])]):_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.displayMetadata)+" ")]),_c('v-list-item-content',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(item.displayBody)+" ")])],1)],1),(index < _vm.newsList.length - 1)?_c('v-divider',{key:index + 'A'}):_vm._e()]})],2)],1)],1)],1)],1),_c('Alerts'),_c('Login')],1)}
var staticRenderFns = []

export { render, staticRenderFns }