import {storage, db} from "../firebase"
import { getDownloadURL,uploadBytes, ref } from "firebase/storage"
import { getDoc, doc, setDoc } from "firebase/firestore"; 

export async function updateUserImage(userId, image){
    /** Receives a user and an image and updates in on the cloud
     * 
     */
    let imageUrl = 'users/'+userId+'/profile-picture'
    let imageRef = ref(storage, imageUrl);
    await uploadBytes(imageRef, image)
    let downloadUrl = await getDownloadURL(imageRef) 
    await setDoc(doc(db, "users", userId), {photoURL: downloadUrl}, { merge: true })
    return downloadUrl
}

export async function getUserImageUrl(userId){
    /** Receives a userId and returns the imageUrl for it
     * 
     */
    // let imageUrl = 'users/'+userId+'/profile-picture'
    // let imageRef = ref(storage, imageUrl);
    // let downloadUrl = await getDownloadURL(imageRef) 
    // return downloadUrl
    let resp = await getDoc(doc(db, "users", userId))
    resp = resp.data()
    if(resp != null) resp = resp.photoURL
    return resp
}

export async function updateUserInfo(userId, userName){
    /** Receives a userId and userName and updates it on firebase
     * 
     */
    let newUserInfo = {
        "user_name":userName
    }
    await setDoc(doc(db, "users", userId), newUserInfo, { merge: true })
    return newUserInfo
}

export async function getUserInfo(userId){
    /** Receives a userId and returns the user info
     * 
     */
    let resp = await getDoc(doc(db, "users", userId))
    return resp.data()
}


