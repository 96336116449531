<template>
  <v-row justify="center">
    <v-dialog v-model="loginDialog">
      <section id="firebaseui-auth-container"></section>
    </v-dialog>
  </v-row>
</template>

<script>

import { EmailAuthProvider } from "firebase/auth";

export default {
  name: "Login",
  data: () => {
    return {
      uiConfig: {
        callbacks: {
          //clicks email -> renders login again -> ui.isPendingRedirection (on Login component) = true ->
          //signIn Processed-> signInSuccessWithAuthResult called ->
          //if(signInSuccessWithAuthResult) user redirected to successUrl that is defined on the new page where the user lands ->
          //ui shuts down
          signInSuccessWithAuthResult: (authResult) => {
            console.log("auth Result é: ", authResult.user);
            return false;
          },
        },
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            forceSameDevice: false,
            emailLinkSignIn: function () {
              return {};
            },
          },
        ],
        //TODO:select terms of use within the correct language
        tosUrl: () => {
          let routeData = this.$router.resolve({ name: "TOS" });
          window.open(routeData.href, "_blank");
        },
        privacyPolicyUrl: () => {
          let routeData = this.$router.resolve({ name: "PrivacyPolicy" });
          window.open(routeData.href, "_blank");
        },
      },
    };
  },
  mounted() {
    if (this.firebaseGUI.isPendingRedirect()) this.loginDialog = true;
  },
  updated() {
    if (this.loginDialog) {
      this.firebaseGUI.start("#firebaseui-auth-container", this.uiConfig);
    }
  },

  computed: {
    loginDialog: {
      get: function () {
        return this.$store.state.loginDialog;
      },
      set: function (loginDialog) {
        this.$store.commit("setLoginDialog", loginDialog);
      },
    },
    firebaseGUI: {
      get: function () {
        return this.$store.state.firebaseGUI;
      }
    },
  },
};
</script>