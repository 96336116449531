<template>
  <div>
    <v-toolbar>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="ml-n4 ml-md-0">
            <router-link to="/">
              <v-img
                :src="
                  $vuetify.breakpoint.xs
                    ? require('../assets/Red_Arrow_Icon.png')
                    : require('../assets/Topbar_Logo.png')
                "
                contain
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" loading> </v-skeleton-loader>
                </template>
              </v-img>
            </router-link>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="primary"
              class="mr-n4 mr-md-0"
              @click="submitNews"
              @mouseover="
                mixpanel.track('Create news button event', {
                  event_type: 'on hover',
                })
              "
              :loading="loadAddNews"
              :disabled="loadAddNews"
              id="create-news-tour"
            >
              <v-icon left> mdi-square-edit-outline </v-icon>
              {{ $t("action.createNews") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              @click="avatarClk"
              v-if="$store.state.user"
              class="mr-n4 mr-md-0"
              @mouseover="
                mixpanel.track('Avatar button event', {
                  event_type: 'on hover',
                })
              "
            >
              <v-avatar v-if="avatarUrl">
                <!-- TODO: Add loader animation to image when loading -->
                <v-img :src="avatarUrl">
                  <template v-slot:placeholder>
                    <v-skeleton-loader type="avatar" loading>
                    </v-skeleton-loader>
                  </template>
                </v-img>
              </v-avatar>
              <v-icon large v-else>mdi-account-circle</v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="logInClick"
              @mouseover="
                mixpanel.track('Log-in button event', {
                  event_type: 'on hover',
                })
              "
              outlined
              color="secondary"
              class="mr-n4 mr-md-0"
            >
              <v-icon> mdi-account-circle-outline </v-icon>
              <span v-if="!$vuetify.breakpoint.xs">
                {{ $t("action.signIn") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <!-- TODO: In Editor component, take absolute out and add app and bottom to 
    both drawers (profile) -->
    <v-navigation-drawer
      v-model="drawer"
      right
      app
      bottom
      temporary
      v-if="$store.state.user"
    >
      <v-list dense>
        <v-list-item to="/profile">
          <v-list-item-icon>
            <v-icon color="primary">mdi-account-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("profile.update") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOutBtnClick">
          <v-list-item-icon>
            <v-icon color="secondary">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("profile.signOut") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { createNewDocument } from "../api/documents";
import mixpanel from "mixpanel-browser";

export default {
  name: "MainToolbar",

  data: () => ({
    snackbar: false,
    drawer: null,
    checkPendingOnly: false,
    loadAddNews: false,
    mixpanel: mixpanel,
  }),
  methods: {
    signOutBtnClick: function () {
      this.$store.dispatch("signOut");
    },
    submitNews: async function () {
      mixpanel.track("Create news button event", {
        event_type: "click",
      });
      this.loadAddNews = true;
      if (this.$store.state.user) {
        let newDoc = await createNewDocument(
          "<h1></h1>",
          "<a></a>",
          this.$store.state.user.uid
        );
        this.$router.push({ path: "/document/" + newDoc.document_id });
        this.alert = {
          control: true,
          message: this.$t("action.documentCreated"),
        };
      } else this.$store.commit("setLoginDialog", true);
      this.loadAddNews = false;
    },
    logInClick: function () {
      this.$store.commit("setLoginDialog", true);
      mixpanel.track("Log-in button event", {
        event_type: "click",
      });
    },
    avatarClk: function () {
      this.drawer = !this.drawer;
      mixpanel.track("Avatar button event", {
        event_type: "click",
      });
    },
  },

  computed: {
    avatarUrl: {
      get: function () {
        if (this.$store.state.user.photoURL)
          return this.$store.state.user.photoURL;
        else return null;
      },
    },
    alert: {
      get: function () {
        return this.$store.state.alert;
      },
      set: function (alert) {
        this.$store.commit("setAlert", alert);
      },
    },
  },
};
</script>