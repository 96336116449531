<template>
  <div>
    <MainToolbar />
    <v-container>
      <br />
      <v-form v-model="validAvatar" @submit.prevent="avatarEdit">
        <v-row align="center">
          <v-col>
            <h1>{{ $t("profile.picture") }}</h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            text
            type="submit"
            :loading="loadSaveAvatar"
            :disabled="loadSaveAvatar && !validAvatar"
            >{{ $t("action.save") }}</v-btn
          >
        </v-row>
        <v-row align="center">
          <v-col>
            <v-file-input
              @change="previewImage"
              v-model="image"
              :rules="avatarRules"
              accept="image/*"
              :placeholder="$t('profile.avatarInputPlaceholder')"
              prepend-icon="mdi-camera"
              label="Avatar"
            ></v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <!-- TODO: Add loader animation to image when loading -->
            <v-avatar size="8em">
              <v-img :src="avatarUrl" v-if="avatarUrl">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="image" loading> </v-skeleton-loader>
                </template>
              </v-img>
              <v-icon x-large v-else dark>mdi-account-circle</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="nameForm" v-model="valid" @keyup.native.enter="nameEdit">
        <v-row align="center">
          <v-col>
            <h1>{{ $t("profile.name") }}</h1>
          </v-col>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="nameEdit"
            :loading="loadSaveName"
            :disabled="loadSaveName"
            >{{ $t("action.save") }}</v-btn
          >
        </v-row>
        <v-row align="center">
          <v-col>
            <v-text-field
              v-model="profileName"
              :rules="nameRules"
              :counter="70"
              :label="$t('profile.nameSurname')"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <h1>{{ $t("profile.email") }}</h1>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-text-field
              v-model="profileEmail"
              :label="$t('profile.email')"
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <Alerts />
  </div>
</template>

<script>
import MainToolbar from "../components/MainToolbar.vue";
import { updateProfile } from "firebase/auth";
import { updateUserImage, updateUserInfo } from "../api/users";
import Alerts from "../components/Alerts.vue";
import mixpanel from "mixpanel-browser";

export default {
  name: "ProfilePage",
  components: {
    MainToolbar,
    Alerts,
  },
  data: function () {
    return {
      valid: false,
      profileName: null,
      profileEmail: null,
      overflowName: "",
      image: null,
      avatarRules: [
        (value) => !!value || "missing picture",
        (value) =>
          !value || value.size < 60000000 || this.$t("profile.pictureOverflow"),
      ],
      nameRules: [
        //TODO: avoid submission with errors (snackbar to give user a feedback)
        (v) => !!v || "missing name",
        (v) => {
          if (v) {
            if (v.length > 70) return this.$t("profile.nameOverflow");
            else return true;
          } else return true;
        },
      ],
      avatarUrl: null,
      loadSaveName: false,
      loadSaveAvatar: false,
      validAvatar: false,
    };
  },
  methods: {
    //TODO: Add name and surname rules and check to only send when modified
    nameEdit: function () {
      const user = this.$store.state.user;
      //TODO: Check if validated before sending the form through
      this.$refs.nameForm.validate();
      this.loadSaveName = true;
      //HERE
      updateProfile(user, {
        displayName: this.profileName,
      })
        .then(() => {
          this.$store.commit("setUserName", this.profileName);
          mixpanel.track("Updated profile name");
          mixpanel.people.set({
            $name: user.displayName,
          });
          this.loadSaveName = false;
          updateUserInfo(this.$store.state.user.uid, this.user.displayName)
        })
        .catch((error) => {
          // An error occurred
          console.log(error);
          this.loadSaveName = false;
          this.$store.commit("setAlert", { control: true, message: error });
        });
    },
    previewImage() {
      if (this.image) this.avatarUrl = URL.createObjectURL(this.image);
      else {
        this.avatarUrl = this.user.photoURL;
      }
    },
    async avatarEdit() {
      this.loadSaveAvatar = true;
      if (this.image) {
        console.log("image: ", this.image);
        let profileUrl = await updateUserImage(
          this.$store.state.user.uid,
          this.image
        );
        console.log("New avatar url: ", profileUrl);
        updateProfile(this.$store.state.user, {
          photoURL: profileUrl,
        })
          .then(() => {
            this.$store.commit("setUserAvatar", this.avatarUrl);
            mixpanel.track("Updated avatar");
            mixpanel.people.set({
              $avatar: this.$store.state.user.photoURL,
            });
            this.loadSaveAvatar = false;
          })
          .catch((error) => {
            console.log("erro do upload avatar", error);
            this.loadSaveAvatar = false;
            this.$store.commit("setAlert", { control: true, message: error });
          });
      } else {
        this.loadSaveAvatar = false;
        this.$store.commit("setAlert", { control: true, message: "no image" });
      }
    },
  },
  mounted() {
    if (this.user) {
      this.profileName = this.user.displayName;
      this.profileEmail = this.user.email;
      this.avatarUrl = this.user.photoURL;
    }
  },
  beforeUpdate() {},
  computed: {
    user: {
      get: function () {
        return this.$store.state.user;
      },
    },
  },
};
</script>