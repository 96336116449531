<template>
  <div>

    <Editor :locked="true" :initial-html="documentPath()"/>
  </div>
</template>

<script>
import Editor from "../components/EditorComponent.vue";
import mixpanel from "mixpanel-browser";

//console.log(initialHtml)
export default {
  name: "TOS",
  components: {
    Editor,
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          mixpanel.track("Scrolled to the bottom_TOS", { document_id: "xxx" });
        }
      };
    },
    documentPath() {
      if (
        navigator.language.split("-")[0] ||
        navigator.userLanguage.split("-")[0] == "pt"
      )
        return "TOS_Pt_Br.json";
      else return "TOS.json";
    },
  },
  mounted() {
    //console.log(this.initialHtml)
    mixpanel.track("Opened document_TOS", { document_id: "xxx" });
    mixpanel.people.increment("document_views_TOS");
  },
};
</script>
