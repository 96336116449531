import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1A6A1D',
                secondary: '#03022E',
                warning: '#E2B93B',
                success: '#27AE60',
                info: '#2F80ED',
                error: '#FE0000',
                background: '#ffffff',
            },
            dark: {
                primary: '#03022E',
                secondary: '#C4AF96',
                warning: '#E2B93B',
                success: '#27AE60',
                info: '#2F80ED',
                error: '#FE0000',
                background: '#c7ffd4',
            },
        },
    },
});
