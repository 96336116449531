<template>
  <v-app
    id="nav"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  ><div id="app">
    <router-view></router-view>
    <!-- <router-link to="/explore">Explore</router-link> |
    <router-link to="/document">Document</router-link> |
    <router-link to="/profile">Profile</router-link> -->
    </div>
  </v-app>
</template>

<script>
import mixpanel from "mixpanel-browser";
export default {
  name: "App",
  components: {},
  data: function(){
    const lang = 'en';
    return{
      lang: lang
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  mounted(){
    mixpanel.track('New visit')
  }
};
</script>